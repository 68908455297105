<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      scrollable
      overlay-color="none"
      transition="fade-transition"
      content-class="elevation-0 rounded-0"
      @keydown.esc="closeModal"
      @keydown.left.prevent="previousImage"
      @keydown.right.prevent="nextImage"
    >
      <v-overlay :value="true" opacity="0.9">
        <transition appear name="scale-transition">
          <div class="mt-md-5 px-sm-16">
            <div
              class="d-flex justify-space-between align-items-center mb-6 mb-sm-12"
            >
              <span
                class="align-center rounded-xl secondary text-body-2 text-sm-h4 text-center py-3 px-4"
              >
                {{ currentImage + 1 }}/{{ images.length }}
              </span>
              <button @click="closeModal">
                <v-icon large>mdi-close</v-icon>
              </button>
            </div>
            <div class="d-flex align-center">
              <button @click.stop="previousImage()">
                <v-icon>mdi-chevron-left</v-icon>
              </button>
              <v-img
                class="mx-4 mx-sm-16"
                :max-height="$vuetify.breakpoint.smAndDown ? '50vh' : '60vh'"
                :width="$vuetify.breakpoint.smAndDown ? '60vw' : '70vw'"
                min-height="30vh"
                min-width="25vh"
                contain
                :src="images[currentImage]"
              />
              <div>
                <button @click.stop="nextImage()">
                  <v-icon>mdi-chevron-right</v-icon>
                </button>
              </div>
            </div>
          </div>
        </transition>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalImagePreview",
  props: {
    dialog: { type: Boolean, required: true },
    images: { type: Array, required: true },
    currentImage: { type: Number, required: true },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    previousImage() {
      const imagePosition =
        this.currentImage === 0
          ? this.images.length - 1
          : this.currentImage - 1;
      this.$emit("handleImagePosition", imagePosition);
    },
    nextImage() {
      const imagePosition =
        this.currentImage === this.images.length - 1
          ? 0
          : this.currentImage + 1;
      this.$emit("handleImagePosition", imagePosition);
    },
  },
};
</script>

<style scoped lang="scss">
button:focus {
  outline: none;
}
</style>
