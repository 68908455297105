<template>
  <div v-if="packageInfo">
    <div class="elevation-3 rounded-lg px-10 py-6">
      <h2 class="text-h2">Reserva tu paquete</h2>
      <v-row class="mt-5">
        <v-col cols="12" sm="6" md="3">
          <p class="font-weight-bold mb-sm-5">Información del evento</p>
          <v-text-field
            v-model="form.eventName"
            flat
            dense
            outlined
            required
            hide-details="auto"
            single-line
            color="secondary"
            placeholder="Nombre del evento"
            class="rounded-lg v-input--is-focused"
            background-color="transparent"
          />
          <span v-if="errors.eventName" class="error-text">
            {{ errors.eventName }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <p class="mb-sm-5">Fecha</p>
          <v-menu
            :close-on-content-click="true"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.eventDate"
                readonly
                v-bind="attrs"
                outlined
                dense
                class="rounded-lg v-input--is-focused"
                clearable
                clear-icon="mdi-close secondary--text"
                placeholder="Fecha del evento"
                hide-details
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="form.eventDate"
              color="secondary"
              prev-icon="fa-chevron-left secondary--text"
              next-icon="fa-chevron-right secondary--text"
              no-title
              locale="es-ES"
              :allowed-dates="allowedDates"
              :min="new Date().toISOString().substr(0, 10)"
              @input="modelMenu = false"
              @change="logDate"
            />
          </v-menu>
          <span v-if="errors.eventDate" class="error-text">
            {{ errors.eventDate }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <p class="mb-sm-5">Horarios disponibles:</p>
          <div class="d-flex align-self-center">
            <v-select
              v-model="form.selectedSchedule"
              :items="formattedSchedules"
              item-text="time"
              item-value="id"
              label="Seleccionar cantidad"
              flat
              dense
              outlined
              required
              hide-details="auto"
              single-line
              color="secondary"
              item-color="secondary"
              :menu-props="{
                bottom: true,
                closeOnContentClick: true,
                allowOverflow: true,
                offsetY: true,
                rounded: 'md',
                transition: 'slide-y-transition',
              }"
              :no-data-text="scheduleNoDataText"
              class="rounded-lg v-input--is-focused"
              background-color="transparent"
            />
          </div>
          <span v-if="errors.selectedSchedule" class="error-text">
            {{ errors.selectedSchedule }}
          </span>
        </v-col>
        <v-col v-if="packageInfo.extraHour" cols="12" sm="6" md="3">
          <p class="mb-sm-5">Hora extra:</p>
          <div class="d-flex">
            <v-select
              v-model="form.extraHours"
              flat
              dense
              :items="[0, 1]"
              outlined
              required
              hide-details="auto"
              single-line
              color="secondary"
              item-color="secondary"
              class="rounded-lg v-input--is-focused mr-5"
              background-color="transparent"
              :disabled="!packageInfo.extraHour"
            />
            <p class="mb-0">
              {{ numberToCurrency(packageInfo.extraHourPrice) }} costo adicional
            </p>
          </div>
          <span v-if="errors.extraHours" class="error-text">
            {{ errors.extraHours }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="12" sm="6" md="4">
          <p class="font-weight-bold">
            Capacidad del evento
            <span v-if="packageInfo.corkage">y descorche</span>
          </p>
          <div class="d-flex justify-space-start">
            <p class="mr-10">{{ packageInfo.adultsQuantity }} adultos</p>
            <p>{{ packageInfo.kidsQuantity }} niños</p>
          </div>
        </v-col>
        <v-col v-if="packageInfo.extraCapacity > 0" cols="12" sm="6" md="4">
          <p>Persona extra</p>
          <div class="d-flex align-center">
            <v-select
              v-model="form.selectedExtraCapacity"
              :items="extraCapacityOptions"
              label=""
              flat
              dense
              outlined
              required
              hide-details="auto"
              single-line
              color="secondary"
              item-color="secondary"
              class="rounded-lg v-input--is-focused"
              background-color="transparent"
              @change="adjustRemainingCapacity()"
            />
            <p class="ml-4 mb-0">
              {{ numberToCurrency(packageInfo.adultExtraPrice) }} costo por
              persona.
            </p>
          </div>
          <span v-if="errors.selectedExtraCapacity" class="error-text">
            {{ errors.selectedExtraCapacity }}
          </span>
        </v-col>
        <v-col v-if="packageInfo.corkage" cols="12" sm="6" md="4">
          <p>Descorche para proveedores externos</p>
          <div class="d-flex justify-space-between align-items-center">
            <v-radio-group v-model="form.corkage" row class="mt-0">
              <v-radio
                label="Sí"
                :value="true"
                color="secondary"
                :ripple="false"
                class="mb-1"
              />
              <v-radio
                label="No"
                :value="false"
                color="secondary"
                :ripple="false"
                class="mb-1"
              />
            </v-radio-group>
            <p class="mb-0">
              {{ numberToCurrency(packageInfo.corkagePrice) }} costo adicional
            </p>
          </div>
        </v-col>
      </v-row>
      <p class="font-weight-bold mt-5 mt-sm-0">Selección de platillos</p>

      <p v-if="packageInfo.adultsQuantity > 0" class="font-weight-bold mt-sm-9">
        Adultos
      </p>
      <v-row>
        <template v-for="dish in packageInfo.packageDishes">
          <v-col v-if="dish.isForAdult" :key="dish.id" cols="12" sm="6" md="4">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="dish.selected"
                color="secondary"
                :ripple="false"
                label=""
                @change="toggleDish(dish, true)"
              />
              <label>{{ dish.name }}</label>
              <v-text-field
                v-model.number="dish.quantity"
                type="number"
                flat
                dense
                outlined
                required
                min="0"
                hide-details="auto"
                single-line
                :disabled="!dish.selected"
                color="secondary"
                class="rounded-lg ml-2 v-input--is-focused"
                background-color="transparent"
                :rules="numberInput"
                @change="validateQuantity(dish, true)"
              />
            </div>
          </v-col>
        </template>
      </v-row>

      <p v-if="packageInfo.kidsQuantity > 0" class="font-weight-bold mt-4">
        Niños
      </p>
      <v-row>
        <template v-for="dish in packageInfo.packageDishes">
          <v-col v-if="!dish.isForAdult" :key="dish.id" cols="12" sm="6" md="4">
            <div class="d-flex align-center">
              <v-checkbox
                v-model="dish.selected"
                color="secondary"
                :ripple="false"
                label=""
                @change="toggleDish(dish, false)"
              />
              <label>{{ dish.name }}</label>
              <v-text-field
                v-model.number="dish.quantity"
                type="number"
                flat
                dense
                outlined
                required
                min="0"
                hide-details="auto"
                single-line
                :disabled="!dish.selected"
                color="secondary"
                class="rounded-lg ml-2 v-input--is-focused"
                background-color="transparent"
                :rules="numberInput"
                @change="validateQuantity(dish, false)"
              />
            </div>
          </v-col>
        </template>
      </v-row>
      <div
        v-if="packageInfo.packageSupplementaryItems.length !== 0"
        class="mt-5"
      >
        <p class="font-weight-bold mb-6">Elementos adicionales</p>
        <v-row>
          <v-col>
            <p class="ml-7">Cantidad</p>
            <div
              v-for="item in packageInfo.packageSupplementaryItems"
              :key="item.id"
              class="d-flex align-center mb-2"
            >
              <v-sheet
                width="32"
                height="32"
                class="d-flex align-center justify-center rounded-circle"
              >
                <v-btn
                  class="secondary white--text pa-0 ma-0 circle-btn"
                  icon
                  @click="decreaseQuantity(item)"
                >
                  -
                </v-btn>
              </v-sheet>
              <p class="mx-5 my-0">{{ item.quantity || 0 }}</p>
              <v-sheet
                width="32"
                height="32"
                class="d-flex align-center justify-center rounded-circle"
              >
                <v-btn
                  class="secondary white--text pa-0 ma-0 circle-btn"
                  icon
                  @click="increaseQuantity(item)"
                >
                  +
                </v-btn>
              </v-sheet>
            </div>
          </v-col>
          <v-col>
            <p>Elemento</p>
            <p
              v-for="item in packageInfo.packageSupplementaryItems"
              :key="item.id"
            >
              {{ item.name }}
            </p>
          </v-col>
          <v-col>
            <p>Variación</p>
            <p
              v-for="item in packageInfo.packageSupplementaryItems"
              :key="item.id"
            >
              {{ item.unit }}
            </p>
          </v-col>
          <v-col>
            <p>Costo unitario</p>
            <p
              v-for="item in packageInfo.packageSupplementaryItems"
              :key="item.id"
            >
              {{ numberToCurrency(item.price) }} MXN
            </p>
          </v-col>
        </v-row>
      </div>

      <v-textarea
        v-model="form.additionalComment"
        solo
        flat
        dense
        no-resize
        outlined
        required
        hide-details
        :rows="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 2 : 3"
        color="secondary"
        row-height="25"
        class="rounded-lg mt-5 mt-md-15 v-input--is-focused"
        background-color="transparent"
        maxlength="255"
        placeholder="Comentarios adicionales"
      >
      </v-textarea>
      <v-row class="mt-5 mt-md-11">
        <v-col cols="12" md="7" offset-md="5">
          <div>
            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <p>Costo de paquete (IVA incluido)</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>
                  {{
                    numberToCurrency(
                      packagePrice && packagePrice.price
                        ? packagePrice.price
                        : 0
                    )
                  }}
                  MXN
                </p>
              </v-col>
            </v-row>

            <v-row v-if="form.selectedExtraCapacity" no-gutters>
              <v-col cols="12" sm="7">
                <p>Persona extra ({{ form.selectedExtraCapacity }})</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>
                  {{
                    numberToCurrency(
                      packageInfo.adultExtraPrice * form.selectedExtraCapacity
                    )
                  }}
                  MXN
                </p>
              </v-col>
            </v-row>

            <v-row v-if="form.corkage" no-gutters>
              <v-col cols="12" sm="7">
                <p>Descorche para proveedores</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>
                  {{
                    form.corkage
                      ? numberToCurrency(packageInfo.corkagePrice)
                      : "$0.00"
                  }}
                  MXN
                </p>
              </v-col>
            </v-row>

            <v-row v-if="form.extraHours !== 0" no-gutters>
              <v-col cols="12" sm="7">
                <p>Hora extra</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>
                  {{
                    form.extraHours
                      ? numberToCurrency(
                          packageInfo.extraHourPrice * form.extraHours
                        )
                      : "$0.00"
                  }}
                  MXN
                </p>
              </v-col>
            </v-row>

            <v-row
              v-for="(item, index) in form.supplementaryItems"
              :key="index"
              no-gutters
            >
              <v-col cols="12" sm="7">
                <p>
                  {{
                    item.name +
                    " (" +
                    item.unit +
                    ")" +
                    " (" +
                    item.quantity +
                    ")"
                  }}
                </p>
              </v-col>
              <v-col class="text-sm-right">
                <p>{{ numberToCurrency(item.quantity * item.price) }} MXN</p>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <p>Comisión Zaturna (5%)</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>{{ numberToCurrency(calculateZaturnaCommission) }} MXN</p>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <p class="font-weight-bold">Total</p>
              </v-col>
              <v-col class="text-sm-right">
                <p class="font-weight-bold">
                  {{ numberToCurrency(calculateTotal) }} MXN
                </p>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="7">
                <p>Anticipo</p>
              </v-col>
              <v-col class="text-sm-right">
                <p>$1,000.00 MXN</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <Button
          text="Reservar"
          class="my-7"
          aria-label="Abrir ventana del registro de usuario"
          @event="login()"
        />
      </div>
    </div>
    <ModalLoginRegister
      :dialog="dialog"
      :modal-type="modalType"
      :redirect-login="redirectLogin"
      :redirect-register="redirectRegister"
      @closeModal="handleModal"
      @hanldeModalType="hanldeModalType"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_AVAILABLE_PACKAGE_SCHEDULE } from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import Button from "@/components/Shared/Button.vue";
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import ModalLoginRegister from "@/components/LandingPage/Shared/Modal/ModalLoginRegister.vue";
import { numberInput } from "@/Utils/rules.js";

export default {
  name: "ReservationForm",
  components: {
    Button,
    ModalLoginRegister,
  },
  props: {
    packageInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    redirectLogin: "UserMainPage",
    redirectRegister: "UserMainPage",
    dialog: false,
    modalType: "",
    form: {
      eventName: "",
      eventdate: "",
      additionalComment: "",
      selectedSchedule: null,
      availableTimes: "",
      extraHour: false,
      extraHours: 0,
      adultsQuantity: 0,
      kidsQuantity: 0,
      extraPerson: 0,
      corkage: false,
      adultDishes: [],
      kidsDishes: [],
      supplementaryItems: [],
      selectedExtraCapacity: 0,
    },
    selectedExtraCapacity: null,
    availableSchedules: [],
    selectedSchedule: null,
    reservationRequest: {},
    packagePrice: null,
    errors: {},
    numberInput: numberInput,
  }),
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    /**
     * Generates an array of numbers from 0 to the value of `extraCapacity` in `packageInfo`.
     *
     * @returns {number[]} An array of numbers starting from 0 up to `extraCapacity` (inclusive).
     */
    extraCapacityOptions() {
      return Array.from(
        { length: this.packageInfo.extraCapacity + 1 },
        (v, k) => k
      );
    },
    /**
     * Creates a function that checks if a given date is allowed based on the package prices.
     *
     * @returns {function(Date): boolean} A function that takes a Date object and returns true if the date is allowed, false otherwise.
     */
    allowedDates() {
      // Get the allowed days from the package prices
      const allowedDays = this.packageInfo.packagePrices.map((price) =>
        price.dayOfWeek.toLowerCase()
      );
      // Mapping of day of the week numbers to names
      const dayNames = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];

      return (date) => {
        const dayNumber = new Date(date).getDay(); // Obtener el número del día de la semana
        const dayName = dayNames[dayNumber]; // Obtener el nombre del día de la semana
        return allowedDays.includes(dayName);
      };
    },
    /**
     * Formats the available schedules with additional time range information.
     *
     * @returns {Object[]} An array of formatted schedule objects, each containing id, time, timeStart, and timeEnd properties.
     */
    formattedSchedules() {
      return this.availableSchedules.map((schedule) => ({
        id: schedule.id,
        time: `${schedule.timeStart} - ${schedule.timeEnd}`,
        timeStart: schedule.timeStart,
        timeEnd: schedule.timeEnd,
      }));
    },
    totalAdultDishes() {
      return this.packageInfo.packageDishes.reduce((acc, dish) => {
        return dish.isForAdult ? acc + (dish.quantity || 0) : acc;
      }, 0);
    },
    totalKidsDishes() {
      return this.packageInfo.packageDishes.reduce((acc, dish) => {
        return !dish.isForAdult ? acc + (dish.quantity || 0) : acc;
      }, 0);
    },
    totalDishes() {
      return this.totalAdultDishes + this.totalKidsDishes;
    },
    maxAdultDishes() {
      return (
        this.packageInfo.adultsQuantity +
        Math.min(
          this.packageInfo.remainingExtraCapacity,
          this.form.selectedExtraCapacity
        )
      );
    },
    maxKidsDishes() {
      return (
        this.packageInfo.kidsQuantity +
        Math.min(
          this.packageInfo.remainingExtraCapacity,
          this.form.selectedExtraCapacity
        )
      );
    },
    /**
     * Calculates the total sum of costs based on package price, selected extra capacity,
     * corkage, extra hours, and additional items.
     *
     * @returns {number} The total sum of all costs.
     */
    calculateSumOfCosts() {
      let total = 0;
      // Check if this.packagePrice is not null or undefined and has a price property
      if (this.packagePrice && this.packagePrice.price) {
        total += this.packagePrice.price;
      }
      // Add cost for additional capacity
      total +=
        this.packageInfo.adultExtraPrice * this.form.selectedExtraCapacity;
      // Add cost for corkage if applicable
      if (this.form.corkage) {
        total += this.packageInfo.corkagePrice;
      }
      // Add cost for extra hours if applicable
      if (this.form.extraHours) {
        total += this.packageInfo.extraHourPrice * this.form.extraHours;
      }
      // Add costs for each additional item
      this.form.supplementaryItems.forEach((item) => {
        total += item.quantity * item.price;
      });

      return total;
    },
    calculateZaturnaCommission() {
      return this.calculateSumOfCosts * 0.05;
    },
    calculateTotal() {
      return this.calculateSumOfCosts + this.calculateZaturnaCommission;
    },
    scheduleNoDataText() {
      return this.form.eventDate
        ? "Sin horarios disponibles"
        : "Seleccione una fecha";
    },
  },
  created() {
    if (this.packageInfo && this.packageInfo.packageSupplementaryItems) {
      this.packageInfo.packageSupplementaryItems.forEach((item) => {
        if (typeof item.quantity === "undefined") {
          this.$set(item, "quantity", 0);
        }
      });
    }
  },
  methods: {
    /**
     * Calculates the cancellation deadline by subtracting a given number of days from the event date.
     *
     * @param {string|Date} eventDate - The date of the event.
     * @param {number} daysToSubtract - The number of days to subtract from the event date.
     * @returns {number} The cancellation deadline as a Unix timestamp (seconds since January 1, 1970).
     */
    calculateCancellationDeadline(eventDate, daysToSubtract) {
      const eventDateObj = new Date(eventDate);
      eventDateObj.setDate(eventDateObj.getDate() - daysToSubtract);
      return Math.floor(eventDateObj.getTime() / 1000);
    },
    validateForm() {
      this.errors = {};
      if (!this.form.eventName) {
        this.errors.eventName = "El nombre del evento es obligatorio";
      }
      if (!this.form.eventDate) {
        this.errors.eventDate = "La fecha del evento es obligatoria";
      }
      if (!this.form.selectedSchedule) {
        this.errors.selectedSchedule = "El horario del evento es obligatorio";
      }
    },
    login() {
      this.hanldeModalType("login");
      this.handleModal();
    },
    hanldeModalType(type) {
      this.modalType = type;
    },
    handleModal() {
      this.dialog = !this.dialog;
    },
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
    numberToCurrency(number) {
      return numberToCurrency(number / 100);
    },
    toggleDish(dish, isForAdult) {
      if (!dish.selected) {
        dish.quantity = 0;
      }
      this.validateQuantity(dish, isForAdult);
    },
    /**
     * Validates and adjusts the quantity of dishes based on the capacity limits for adults and kids.
     *
     * @param {Object} dish - The dish object containing the quantity.
     * @param {boolean} isForAdult - Indicates if the dish is for adults.
     */
    validateQuantity(dish, isForAdult) {
      const maxAdultsCapacity =
        this.packageInfo.adultsQuantity + this.form.selectedExtraCapacity;
      const maxKidsCapacity =
        this.packageInfo.kidsQuantity + this.form.selectedExtraCapacity;

      if (isForAdult) {
        const totalAdultDishes = this.totalAdultDishes;
        if (totalAdultDishes > maxAdultsCapacity) {
          const excess = totalAdultDishes - maxAdultsCapacity;
          if (dish.quantity > excess) {
            dish.quantity -= excess;
          } else {
            dish.quantity = 0;
          }
        }
      } else {
        const totalKidsDishes = this.totalKidsDishes;
        if (totalKidsDishes > maxKidsCapacity) {
          const excess = totalKidsDishes - maxKidsCapacity;
          if (dish.quantity > excess) {
            dish.quantity -= excess;
          } else {
            dish.quantity = 0;
          }
        }
      }

      this.adjustRemainingCapacity();
    },
    /**
     * Adjusts the remaining capacity by checking if the total number of dishes exceeds the maximum allowed.
     * If there is an excess, it reduces the quantities of selected dishes.
     */
    adjustRemainingCapacity() {
      const totalAdults =
        this.packageInfo.adultsQuantity + this.form.selectedExtraCapacity;
      let totalAdultDishes = this.totalAdultDishes;
      let totalKidDishes = this.totalKidsDishes;

      const totalDishes = totalAdultDishes + totalKidDishes;
      const maxTotalDishes =
        this.packageInfo.adultsQuantity +
        this.packageInfo.kidsQuantity +
        this.form.selectedExtraCapacity;

      if (totalDishes > maxTotalDishes) {
        let excessTotal = totalDishes - maxTotalDishes;
        if (totalAdultDishes > totalAdults) {
          let excessAdults = totalAdultDishes - totalAdults;
          this.adjustDishes(true, excessAdults);
          excessTotal -= excessAdults;
          totalAdultDishes = totalAdults;
        }

        if (excessTotal > 0) {
          this.adjustDishes(false, excessTotal);
        }
      }

      const usedExtraCapacity = Math.max(
        0,
        totalDishes -
          (this.packageInfo.adultsQuantity + this.packageInfo.kidsQuantity)
      );
      this.packageInfo.remainingExtraCapacity =
        this.form.selectedExtraCapacity - usedExtraCapacity;
    },
    /**
     * Adjusts the quantity of selected dishes for adults or kids based on the excess value.
     *
     * @param {boolean} isAdult - Indicates whether the adjustment is for adult dishes (true) or kid dishes (false).
     * @param {number} excess - The amount by which to reduce the total quantity of dishes.
     */
    adjustDishes(isAdult, excess) {
      const dishes = this.packageInfo.packageDishes.filter(
        (dish) => dish.isForAdult === isAdult && dish.selected
      );
      // Sort the dishes in descending order by quantity.
      dishes.sort((a, b) => b.quantity - a.quantity);

      // Loop through the sorted dishes and reduce their quantities to adjust for the excess.
      for (let i = dishes.length - 1; i >= 0 && excess > 0; i--) {
        const dish = dishes[i];
        if (dish.quantity > excess) {
          dish.quantity -= excess;
          excess = 0;
        } else {
          excess -= dish.quantity;
          dish.quantity = 0;
        }
      }
    },
    async fetchAvailablePackageSchedule(unixDate) {
      const packageId = parseInt(this.$route.params.id);
      const { data, errors } = await useQuery(GET_AVAILABLE_PACKAGE_SCHEDULE, {
        packageId: packageId,
        date: unixDate,
      });
      if (data) {
        this.availableSchedules = data.availablePackageSchedule.filter(
          (schedule) => schedule.isAvailable
        );
      } else if (errors) {
        console.log(errors);
      }
    },
    /**
     * Converts the event date to a Unix timestamp, logs it, and fetches available package schedules.
     * Also finds the package price based on the day of the week.
     */
    logDate() {
      const unixTimestamp = (
        new Date(this.form.eventDate).getTime() / 1000
      ).toString();
      this.fetchAvailablePackageSchedule(unixTimestamp);
      this.findPackagePriceByDayOfWeek();
    },
    /**
     * Finds and logs the package price based on the day of the week from the selected event date.
     */
    findPackagePriceByDayOfWeek() {
      const selectedDate = new Date(this.form.eventDate);
      // Ensure the date is correctly adjusted
      selectedDate.setHours(0, 0, 0, 0);
      // Get the day of the week index without timezone adjustments
      const dayOfWeekIndex = selectedDate.getDay();
      // Define the names of the days of the week
      const daysOfWeek = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
      const dayOfWeek = daysOfWeek[dayOfWeekIndex].toLowerCase();
      // Find the package price by day of the week
      this.packagePrice = this.packageInfo.packagePrices.find(
        (price) => price.dayOfWeek.toLowerCase() === dayOfWeek
      );
    },
    increaseQuantity(item) {
      if (!item.quantity) {
        this.$set(item, "quantity", 0);
      }
      item.quantity++;

      const existingItem = this.form.supplementaryItems.find(
        (i) => i.id === item.id
      );
      if (existingItem) {
        existingItem.quantity = item.quantity;
      } else {
        this.form.supplementaryItems.push({
          name: item.name,
          unit: item.unit,
          quantity: item.quantity,
          id: item.id,
          price: item.price,
        });
      }
    },
    decreaseQuantity(item) {
      if (item.quantity > 0) {
        item.quantity--;

        const existingItem = this.form.supplementaryItems.find(
          (i) => i.id === item.id
        );
        if (existingItem) {
          existingItem.quantity = item.quantity;
          if (item.quantity === 0) {
            const index = this.form.supplementaryItems.indexOf(existingItem);
            this.form.supplementaryItems.splice(index, 1);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-select__selection,
::v-deep .v-select__selection--comma,
::v-deep .v-select.v-text-field input {
  color: black !important;
}

.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.box-card-shadow {
  box-shadow: 4px 2px 14px 0px rgba(0, 0, 0, 0.35);
}

.btn-background-transparent::before {
  background-color: transparent !important;
}

.circle-btn {
  width: 32px;
  height: 32px;
  min-width: 32px;
}

.error-text {
  color: red;
  font-size: 0.75rem;
}
</style>
