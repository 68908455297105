<template>
  <div class="px-md-2 mb-4">
    <div>
      <v-sheet v-if="selectedImage != null" class="rounded-lg">
        <v-img
          :height="$vuetify.breakpoint.xs ? 241 : 483"
          class="rounded-lg cursor-pointer"
          :src="images[selectedImage]"
          @click="handleImagePreviewModal"
        />
      </v-sheet>
      <v-slide-group
        v-model="selectedImage"
        class="px-0 py-2 py-md-6"
        mandatory
        show-arrows
      >
        <v-slide-item
          v-for="(image, i) in images"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="ma-2 ma-md-4 rounded-lg transparent--text"
              flat
              @click="toggle"
            >
              <v-img
                :width="$vuetify.breakpoint.xs ? 60 : 150"
                :style="
                  active ? 'opacity: 0.9;' : hover && 'border: 1px solid white'
                "
                :gradient="
                  active ? 'to bottom, rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)' : ''
                "
                :height="$vuetify.breakpoint.xs ? 60 : 150"
                :src="image"
                class="rounded-lg"
              />
            </v-card>
          </v-hover>
        </v-slide-item>
      </v-slide-group>
    </div>
    <ModalImagePreview
      :dialog="imagePreviewModal"
      :images="images"
      :current-image="selectedImage"
      @closeModal="handleImagePreviewModal"
      @handleImagePosition="handleImagePosition"
    />
  </div>
</template>
<script>
import ModalImagePreview from "@/components/Shared/Modal/ModalImagePreview.vue";

export default {
  name: "ImageSlideshow",
  components: {
    ModalImagePreview,
  },
  props: {
    images: { type: Array, required: true },
  },
  data() {
    return {
      selectedImage: 0,
      imagePreviewModal: false,
    };
  },
  methods: {
    handleImagePreviewModal() {
      this.imagePreviewModal = !this.imagePreviewModal;
    },
    handleImagePosition(imagePosition) {
      this.selectedImage = imagePosition;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
