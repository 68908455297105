<template>
  <div class="d-flex flex-column align-center my-16">
    <v-progress-circular
      indeterminate
      color="secondary"
      :width="5"
      :size="120"
      class="my-5 my-sm-16"
    />
  </div>
</template>

<script>
export default {
  name: "InlineLoading",
};
</script>
