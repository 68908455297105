<template>
  <div class="px-2 mb-14">
    <v-row>
      <v-col cols="8" class="pa-3 pr-1">
        <v-sheet v-if="selectedImage != null" class="rounded-lg">
          <v-img
            height="556px"
            class="rounded-lg cursor-pointer"
            :src="images[selectedImage]"
            @click="handleImagePreviewModal"
          />
        </v-sheet>
      </v-col>
      <v-col cols="4" class="py-3 px-0">
        <v-item-group
          v-model="selectedImage"
          mandatory
          style="height: 556px"
          class="overflow-y-auto scroller"
        >
          <v-item
            v-for="(image, i) in images"
            :key="i"
            v-slot="{ active, toggle }"
          >
            <v-card
              class="ma-6 mt-0 rounded-lg transparent--text"
              :class="i === images.length - 1 && 'mb-0'"
              flat
              @click="toggle"
            >
              <v-img
                :width="417"
                :height="262"
                :style="active ? 'opacity: 0.99; border: 2px solid white' : ''"
                :gradient="
                  active ? 'to bottom, rgba(0,0,0, 0.3), rgba(0,0,0, 0.3)' : ''
                "
                :src="image"
                class="rounded-lg"
              />
            </v-card>
          </v-item>
        </v-item-group>
      </v-col>
    </v-row>
    <ModalImagePreview
      :dialog="imagePreviewModal"
      :images="images"
      :current-image="selectedImage"
      @closeModal="handleImagePreviewModal"
      @handleImagePosition="handleImagePosition"
    />
  </div>
</template>
<script>
import ModalImagePreview from "@/components/Shared/Modal/ModalImagePreview.vue";

export default {
  name: "ImageSlideshowTwo",
  components: {
    ModalImagePreview,
  },
  props: {
    images: { type: Array, required: true },
  },
  data() {
    return {
      selectedImage: 0,
      imagePreviewModal: false,
    };
  },
  methods: {
    handleImagePreviewModal() {
      this.imagePreviewModal = !this.imagePreviewModal;
    },
    handleImagePosition(imagePosition) {
      this.selectedImage = imagePosition;
    },
  },
};
</script>

<style lang="scss" scoped>
.scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  background-color: #f2f2f2;
}
.scroller::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--v-secondary-base);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.scroller {
  overflow-y: scroll;
  scrollbar-color: var(--v-secondary-base) #f2f2f2;
  scrollbar-width: thin;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
