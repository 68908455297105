/**
 * Formats number into MXN peso currency
 * @param {Number} num Number to be formatted
 * @returns {String} Formatted number (Ex. $2,500.00).
 */
export const numberToCurrency = (num) => {
  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  });

  return formatter.format(num);
};

/**
 * Convert number to phone number format
 * @param {*} num 10 digit number
 * @returns {string} (###)###-####
 */
export const numberToPhone = (num) => {
  var cleaned = ("" + num).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
