<template>
  <div v-if="dialog">
    <v-dialog
      v-model="dialog"
      max-width="860px"
      @click:outside="closeModal"
      @keydown.esc="closeModal"
    >
      <v-card class="pa-10 pa-md-12" style="max-height: 490px">
        <v-card-title class="mt-2 pa-0">
          <div class="font-weight-bold text-h3" style="word-break: break-word">
            {{ name }}
          </div>
          <v-btn
            icon
            :ripple="false"
            absolute
            right
            top
            class="btn-background-transparent hover-close-btn"
            @click="closeModal"
          >
            <v-icon large color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <span
          class="mt-2 ml-1 text-subtitle-1 text-sm-h4 font-weight-regular d-flex align-center"
        >
          <v-icon class="mr-2 secondary--text">fa-map-pin</v-icon>

          {{ address }}
        </span>
        <v-card-text class="mt-8 mt-md-16 pa-0 text-center">
          <Button
            text="Abrir en Google Maps"
            class="mx-0 ma-md-0"
            aria-label="Ir a dirección en Google Maps"
            is-external
            :url="'http://maps.google.com/?q=' + encodeURIComponent(address)"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Button from "@/components/Shared/Button.vue";

export default {
  name: "ModalLocation",
  components: {
    Button,
  },
  props: {
    dialog: { type: Boolean, required: true },
    name: { type: String, required: true },
    address: { type: String, required: true },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped lang="scss">
.btn-background-transparent::before {
  background-color: transparent !important;
}

.hover-close-btn:hover .v-icon {
  transform: scale(1.1);
}
</style>
